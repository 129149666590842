'use client';

import { useRef, useEffect } from 'react';
import { usePathname, useParams, useSearchParams } from 'next/navigation';
import gsap from 'gsap';
import { CustomEase, SplitText } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';

//* HOC's
import { withUIContext } from '@/context';

function ScrollWrapper({ children, isBurgerMenuOpen, popupIsOpen, winWidth }) {
	//! Refs
	const main = useRef();
	const smoother = useRef();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();
	const searchparams = useSearchParams();

	//! Scroll Top In Router Change
	useEffect(() => {
		if (smoother.current != undefined || smoother.current != null) {
			gsap.to(smoother.current, {
				scrollTop: 0,
				duration: 0,
			});
		}
		// //! searchparams not dynamic
	}, [pathname, params.lang, searchparams.get('regionId'), searchparams.get('categoryId'), searchparams.get('page')]);

	useGSAP(
		() => {
			//? Scroll off mobile
			let hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints;
			let isDesktop = winWidth >= 1280;

			//? Plugin
			gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText, CustomEase);

			//? Smoother Desktop
			if (!hasTouchScreen && isDesktop && !pathname.includes('video-example')) {
				smoother.current = ScrollSmoother.create({
					smooth: 0.7,
					speed: 1,
					ease: CustomEase.create('custom', 'M0,0 C0.11,0.354 0.134,0.963 1,1 '),
					normalizeScroll: { lockAxis: false, allowNestedScroll: true },
				});
			}
		},
		{ scope: main }
	);

	useEffect(() => {
		if (smoother.current != undefined || smoother.current != null) {
			smoother.current.paused(isBurgerMenuOpen || popupIsOpen, true);
		}
	}, [isBurgerMenuOpen, popupIsOpen]);

	return (
		<div
			ref={main}
			id='smooth-wrapper'>
			<div id='smooth-content'>{children}</div>
		</div>
	);
}

export default withUIContext(ScrollWrapper, ['isBurgerMenuOpen', 'popupIsOpen', 'winWidth']);

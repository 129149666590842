import styled from 'styled-components'

const PopupStyle = styled.div`
	--popupMaxWidth: var(--sp168x);
	--popupMaxHeight: 100vh;
	--popupContentMaxHeight: 100%;
	--popupPadTop: var(--sp6x);
	--popupPadBot: var(--sp6x);
	--popupPadRL: var(--sp20x);
	--closeSize: var(--h6);
	--closePosTopSize: var(--sp5x);
	--closePosRightSize: var(--sp5x);

	position: fixed;
	width: 100vw;
	inset: 0;
	z-index: 100000;

	&::-webkit-scrollbar {
		display: none;
	}

	.popup-background {
		background-color: rgba(0, 0, 0, 0.5);
		pointer-events: all;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

        &.thankYou-show {
            background-color: transparent;
        }
	}

	.popup-content-container {
		max-width: var(--popupMaxWidth);
		max-height: var(--popupMaxHeight);
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		pointer-events: all;
		overflow: scroll;
		margin: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		.popup-content-wrap {
			position: relative;
			width: 100%;
			height: fit-content;
			max-height: var(--popupContentMaxHeight);
			padding: var(--popupPadTop) var(--popupPadRL) var(--popupPadBot) var(--popupPadRL);
			background: var(--backgroundColor);
			box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.1);
			border-radius: var(--sp3x);
			opacity: 1;
			overflow-x: hidden;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			background-color: var(--white);

            &.thankYou-show {
                display: flex;
                justify-content: center;
                background-color: transparent;
                box-shadow: none;
            }

			&::-webkit-scrollbar {
				display: none;
			}

			.close-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				width: var(--closeSize);
				height: var(--closeSize);
				top: var(--closePosTopSize);
				right: var(--closePosRightSize);
				z-index: 2;

				i {
					font-size: var(--closeSize);
					color: var(--primaryColor1);
					cursor: pointer;
				}
			}

            .title {
                text-align: left;
            }
		}
	}

	//! Message Popup Styles and Sizes
	&.messagePopup {
		--noteCloseBtnPopupMaxWidth: var(--sp97x);
		--noteCloseBtnPopupPad: var(--sp4x);

		--popupMaxWidth: var(--noteCloseBtnPopupMaxWidth);
		--popupPadTop: var(--noteCloseBtnPopupPad);
		--popupPadBot: var(--noteCloseBtnPopupPad);
		--popupPadRL: var(--noteCloseBtnPopupPad);

		.popup-content-container {
			.popup-content-wrap {
				.close-wrap {
					display: none;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--popupMaxWidth: var(--sp128x);
		--popupPadTop: var(--sp5x);
		--popupPadBot: var(--sp5x);
		--popupPadRL: var(--sp15x);
		--closePosTopSize: var(--sp3x);
		--closePosRightSize: var(--sp3x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp3x) !important;
		--noteCloseBtnPopupMaxWidth: var(--sp80x) !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--popupMaxWidth: var(--sp101x);
		--popupPadTop: var(--sp6x);
		--popupPadBot: var(--sp6x);
		--popupPadRL: var(--sp8x);
		--closePosTopSize: var(--sp3x);
		--closePosRightSize: var(--sp4x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp3x) !important;
		--noteCloseBtnPopupMaxWidth: var(--sp65x) !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--popupMaxWidth: var(--sp101x);
		--popupPadTop: var(--sp6x);
		--popupPadBot: var(--sp6x);
		--popupPadRL: var(--sp8x);
		--closePosTopSize: var(--sp2-5x);
		--closePosRightSize: var(--sp2-5x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp3x) !important;
		--noteCloseBtnPopupMaxWidth: var(--sp63x) !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--popupMaxWidth: var(--sp87x);
		--popupPadTop: var(--sp5x);
		--popupPadBot: var(--sp5x);
		--popupPadRL: var(--sp6x);
		--closePosTopSize: var(--sp2x);
		--closePosRightSize: var(--sp2x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp2-5x) !important;
		--noteCloseBtnPopupMaxWidth: var(--sp55x) !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--popupMaxWidth: var(--sp83x);
		--popupPadTop: var(--sp4x);
		--popupPadBot: var(--sp4x);
		--popupPadRL: var(--sp6x);
		--closePosTopSize: var(--sp2x);
		--closePosRightSize: var(--sp2x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp2-5x) !important;
		--noteCloseBtnPopupMaxWidth: var(--sp48x) !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--popupMaxWidth: var(--sp83x);
		--popupPadTop: var(--sp4x);
		--popupPadBot: var(--sp4x);
		--popupPadRL: var(--sp6x);
		--closePosTopSize: var(--sp2x);
		--closePosRightSize: var(--sp2x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp2-5x) !important;
		--noteCloseBtnPopupMaxWidth: var(--sp48x) !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--popupMaxWidth: calc(100% - calc(2 * var(--contPaddingLR)));
		--popupPadTop: var(--sp2-5x);
		--popupPadBot: var(--sp2-5x);
		--popupPadRL: var(--sp4x);
		--closePosTopSize: var(--sp2x);
		--closePosRightSize: var(--sp2x);
		--popupContentMaxHeight: 95%;

		--noteCloseBtnPopupPad: var(--sp1-5x) !important;
		--noteCloseBtnPopupMaxWidth: calc(100% - calc(2 * var(--contPaddingLR))) !important;
	}
`
export default PopupStyle

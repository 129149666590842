'use client';
import Script from "next/script";

const ThirdParties = () => {
	return (
		<>
			{/* Google Tag Manager */}
			<Script
				strategy="beforeInteractive"
				dangerouslySetInnerHTML={{
					__html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-59R9T3WF');
            `,
				}}
			/>
			{/* End Google Tag Manager */}

			{/* Google Tag Manager (noscript) */}
			<noscript>
				<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59R9T3WF"
						height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe>
			</noscript>
			{/* End Google Tag Manager (noscript) */}

			{/* Service Worker */}
			<script
				dangerouslySetInnerHTML={{
					__html: `
                                var sw = {
                                    "url": "\/service-worker.js"
                                };
                                if ( 'serviceWorker' in navigator ) {
                                    window.addEventListener( 'load', function () {
                                        console.log( 'loading SW' )
                                        navigator.serviceWorker.register( sw.url )
                                            .then( function ( registration ) {
                                                console.log( 'Service worker registered' );
                                            } )
                                            .catch( function ( error ) {
                                                console.log( 'Registration failed with ' + error );
                                            } );
                                    } );
                                }
                            `,
				}}
			/>
		</>
	);
};

export default ThirdParties;

'use client';

import { useMemo } from 'react';
import { usePathname } from 'next/navigation';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';
import withDataContext from '@/context/consumerHOC/DataConsumer';
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Style
import FooterStyle from './style';

//* Components
import CallToAction from '@/components/common/CallToAction';
import CustomLink from '@/components/common/CustomLink';
import Container from '@/components/common/Container';
import Image from '@/components/common/Image';
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';

const AppFooter = ({ winWidth, translate, globalData, selectedLang }) => {
	//! Next Router
	const pathname = usePathname();

	//! Not found check
	const is404 = useMemo(() => {
		return pathname === `/${selectedLang}/not-found/` || pathname === `/not-found/`;
	}, [pathname, selectedLang]);

	const isHome = useMemo(() => {
		return pathname === `/${selectedLang}/` || pathname === `/`;
	}, [pathname, selectedLang]);

	return !is404 ? (
		<FooterStyle className={`footer`}>
			{!isHome ? <CallToAction /> : null}

			<Container>
				<div className='footer-lower'>
					<div className='first-row'>
						{winWidth < 1024 ? (
							<div className='first-row-mobile'>
								<div className='image-info-wrap'>
									<Image
										className={'footer-logo'}
										src={'/images/logo.svg'}
									/>

									<div className='footer-info'>
										<a
											className='h3 main-blue font-montserrat-medium email'
											href={`mailto:${globalData[selectedLang].email}`}>
											{globalData[selectedLang].email}
										</a>

										<a
											className='h3 main-blue font-montserrat-medium phone'
											href={`tel:${globalData[selectedLang].phone_number}`}>
											{globalData[selectedLang].phone_number}
										</a>

										<Text className={'h3 font-montserrat-medium main-blue'}>{globalData[selectedLang].address_line_1}</Text>
										<Text className={'h3 font-montserrat main-blue'}>{globalData[selectedLang].address_line_2}</Text>

										<div className='socials-wrap'>
											{globalData[selectedLang].socials?.linkedin ? (
												<CustomLink
													external
													url={globalData[selectedLang].socials.linkedin}
													className='social'>
													<Icon
														className={'h6 social-icon'}
														name={'linkedin'}
													/>
												</CustomLink>
											) : null}

											{globalData[selectedLang].socials?.facebook ? (
												<CustomLink
													external
													url={globalData[selectedLang].socials.facebook}
													className='social'>
													<Icon
														className={'h6 social-icon'}
														name={'facebook'}
													/>
												</CustomLink>
											) : null}

											{globalData[selectedLang].socials?.twitter ? (
												<CustomLink
													external
													url={globalData[selectedLang].socials.twitter}
													className='social'>
													<Icon
														className={'h6 social-icon'}
														name={'twitter'}
													/>
												</CustomLink>
											) : null}

											{globalData[selectedLang].socials?.instagram ? (
												<CustomLink
													external
													url={globalData[selectedLang].socials.instagram}
													className='social'>
													<Icon
														className={'h6 social-icon'}
														name={'instagram'}
													/>
												</CustomLink>
											) : null}
										</div>
									</div>
								</div>

								<Image
									className={'footer-image'}
									src={'/images/footer/footer-image.svg'}
								/>
							</div>
						) : (
							<>
								<Image
									className={'footer-logo'}
									src={'/images/logo.svg'}
								/>

								<Image
									className={'footer-image'}
									src={'/images/footer/footer-image.svg'}
								/>

								<div className='footer-info'>
									<a
										className='h3 main-blue font-montserrat-medium email'
										href={`mailto:${globalData[selectedLang].email}`}>
										{globalData[selectedLang].email}
									</a>

									<a
										className='h3 main-blue font-montserrat-medium phone'
										href={`tel:${globalData[selectedLang].phone_number}`}>
										{globalData[selectedLang].phone_number}
									</a>

									<Text className={'h3 font-montserrat-medium main-blue'}>{globalData[selectedLang].address_line_1}</Text>
									<Text className={'h3 font-montserrat main-blue'}>{globalData[selectedLang].address_line_2}</Text>

									<div className='socials-wrap'>
										{globalData[selectedLang].socials?.linkedin ? (
											<CustomLink
												external
												url={globalData[selectedLang].socials.linkedin}
												className='social'>
												<Icon
													className={'h6 social-icon'}
													name={'linkedin'}
												/>
											</CustomLink>
										) : null}

										{globalData[selectedLang].socials?.facebook ? (
											<CustomLink
												external
												url={globalData[selectedLang].socials.facebook}
												className='social'>
												<Icon
													className={'h6 social-icon'}
													name={'facebook'}
												/>
											</CustomLink>
										) : null}

										{globalData[selectedLang].socials?.twitter ? (
											<CustomLink
												external
												url={globalData[selectedLang].socials.twitter}
												className='social'>
												<Icon
													className={'h6 social-icon'}
													name={'twitter'}
												/>
											</CustomLink>
										) : null}

										{globalData[selectedLang].socials?.instagram ? (
											<CustomLink
												external
												url={globalData[selectedLang].socials.instagram}
												className='social'>
												<Icon
													className={'h6 social-icon'}
													name={'instagram'}
												/>
											</CustomLink>
										) : null}
									</div>
								</div>
							</>
						)}
					</div>

					<div className='second-row'>
						<Text className={'p p1 font-montserrat-medium main-blue'}>
							{new Date().getFullYear()}. {translate('allRights')}.
						</Text>

						<CustomLink
							external
							className={'p p1 concept-link font-montserrat-medium main-blue'}
							url={'/privacy-policy'}
							target={'_blank'}>
							Privacy Policy
						</CustomLink>

						{/*<CustomLink*/}
						{/*	external*/}
						{/*	className={'p p1 concept-link font-montserrat-medium main-blue'}*/}
						{/*	url={'https://conceptstudio.com/'}*/}
						{/*	target={'_blank'}>*/}
						{/*	by Concept Studio*/}
						{/*</CustomLink>*/}
					</div>
				</div>
			</Container>
		</FooterStyle>
	) : null;
};

export default withDataContext(withUIContext(withLanguageContext(AppFooter, ['translate']), ['winWidth']), ['globalData']);
